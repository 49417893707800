import "react-toastify/dist/ReactToastify.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import { FlowyGlobalProvider } from "flowy-3-core";
import { FlowyNotificationsProvider } from "./components/providers";
import Development from "./components/dev/Development";
import {
  DefaultProject,
  PublicRootGizmo,
  Login,
  PlatformInterface,
  BaseProject,
} from "./client";
import { PrivateRoutes, RedirectToRoot } from "./client/routes";
import config from "./config";
import { antdTheme } from "./client/gizmoConfigurations";
import locale from "antd/locale/es_ES";
import ApprovedSearch from "./ApprovedSearch";
import Closed from "./Closed";
import "./index.css";
import Folio from "./components/dev/Folio";

const packageInfo = require("../package.json");
const inicio = packageInfo.dependencies["flowy-3-core"].indexOf("core-");
const fin = packageInfo.dependencies["flowy-3-core"].indexOf(".tgz");
const clientVersion = packageInfo.version;
let coreVersion = "";
if (inicio !== -1 && fin !== -1 && inicio < fin) {
  const subcadena = packageInfo.dependencies["flowy-3-core"].substring(
    inicio + 5,
    fin
  );
  coreVersion = subcadena;
}

function App() {
  return (
    <div className="notranslate">
      <span>v1.3.8</span>
      <FlowyGlobalProvider
        apiUrl={config.apiUrl}
        loadingComponent={<div>Loading...</div>}
        platformInterface={PlatformInterface}
        clientMetadata={{
          clientVersion,
          coreVersion,
          hostname: window.location.hostname,
          source: "web.3",
        }}
      >
        <ConfigProvider theme={antdTheme} locale={locale}>
          <FlowyNotificationsProvider>
            <Router>
              <Routes>
                {/* <Route path="/" element={<Development />} /> */}
                <Route path="/*" element={<BaseProject />} />
                {/* <Route path="/*" element={<ApprovedSearch />}/> */}
                {/* <Route path="/*" element={<Closed />}/> */}
                {/* <Route element={<PrivateRoutes />}>
                  <Route path="/*" element={<DefaultProject />} />
                </Route>
                <Route path="/p/rg" element={<PublicRootGizmo />} />
                <Route path="/qr_generator" element={<Folio />} />
                <Route element={<RedirectToRoot />}>
                  <Route path="/login" element={<Login />} />
                </Route> */}
              </Routes>
            </Router>
          </FlowyNotificationsProvider>
        </ConfigProvider>
      </FlowyGlobalProvider>
    </div>
  );
}

export default App;
