import { Gizmo, TOnSubmit } from "flowy-3-core";
import { toast } from "react-toastify";
import { loginConfig } from "../gizmoConfigurations";
import { FlowyFoundation } from "../../components/gizmos";
import authentication, { IAuthentication } from "../authentication";
import { foro } from "../../assets/images";

type LoginCustomGizmoProps = {
  gizmo: Gizmo;
};

const LoginCustomGizmo = ({ gizmo }: LoginCustomGizmoProps) => {
  const handleSubmit: TOnSubmit = ({
    rootGizmoData,
    onSubmitFinish,
  }): Promise<void> => {
    return new Promise<void>(async (resolve) => {
      const auth = authentication as IAuthentication;
      const { d } = rootGizmoData;

      if (d) {
        if (!Array.isArray(d["q0"]) && !Array.isArray(d["q1"])) {
          const username = d["q0"]["v"].toString();
          const password = d["q1"]["v"].toString();

          try {
            await auth.login({ username, password });

            // We used this instead of routerHistory in order for the browser
            // to refresh the screen
            // window.location.href = "/f";
            window.location.href = "/";
          } catch (e) {
            toast.error(e.message);
          }
        }
      }

      onSubmitFinish();
      resolve();
    });
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={foro}
          alt="Foro"
          style={{
            maxWidth: "40%",
          }}
        />
      </div>
      <FlowyFoundation config={loginConfig} onSubmit={handleSubmit} />
    </div>
  );
};

export default LoginCustomGizmo;
